<script setup>
import { computed, ref } from '#imports';
import { useAsyncData } from 'nuxt/app';
import { mixed, object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';

import { useSurveyStore } from '@/stores/survey';

import UiSelect from '@/components/ui/form/UiSelect.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import FormWrapper from '@/components/layout/forms/FormWrapper.vue';
import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';

const surveyStore = useSurveyStore();

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({}),
  },
  componentData: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(['update-form']);

//<editor-fold desc="Form">
const marksResponse = await useAsyncData(surveyStore.fetchMarks);
const marks = ref(marksResponse.data.value || []);

const primaryCigaretteSubfamilyList = computed(() => {
  return props.formData.brand?.submarks || [];
});
const secondaryCigaretteSubfamilyList = computed(() => {
  return props.formData.brandAll?.submarks || [];
});
const intervalList = ref([
  { value: 0, name: 'Меньше трех месяцев' },
  { value: 1, name: 'От трех до шести месяцев' },
  { value: 2, name: 'От шести до двенадцати месяцев' },
  { value: 3, name: 'Больше года' },
]);
const percentList = ref([
  { value: 0, name: '10-30%' },
  { value: 1, name: '30-70%' },
  { value: 2, name: '70-100%' },
  { value: 3, name: 'Не курю другие марки' },
]);

const brandData = computed({
  get() {
    return props.formData.brand;
  },
  set(value) {
    emits('update-form', { key: 'brand', value });
  },
});
const primaryCigaretteSubfamilyData = computed({
  get() {
    return props.formData.primaryCigaretteSubfamily;
  },
  set(value) {
    emits('update-form', { key: 'primaryCigaretteSubfamily', value });
  },
});
const cigaretteCountPerDayFieldData = computed({
  get() {
    return props.formData.cigaretteCountPerDayField;
  },
  set(detail = {}) {
    emits('update-form', {
      key: 'cigaretteCountPerDayField',
      value: detail.masked,
    });
  },
});
const primaryBrandAgeFieldData = computed({
  get() {
    return props.formData.primaryBrandAgeField;
  },
  set(value) {
    emits('update-form', { key: 'primaryBrandAgeField', value });
  },
});
const primaryBrandShareFieldData = computed({
  get() {
    return props.formData.primaryBrandShareField;
  },
  set(value) {
    emits('update-form', { key: 'primaryBrandShareField', value });
  },
});
const brandAllData = computed({
  get() {
    return props.formData.brandAll;
  },
  set(value) {
    emits('update-form', { key: 'brandAll', value });
  },
});
const secondaryCigaretteSubfamilyData = computed({
  get() {
    return props.formData.secondaryCigaretteSubfamily;
  },
  set(value) {
    emits('update-form', { key: 'secondaryCigaretteSubfamily', value });
  },
});

function sendForm(values, actions) {
  // добавление валидации поля primaryCigaretteSubfamily если список не пустой
  if (
    primaryCigaretteSubfamilyList.value?.length &&
    !primaryCigaretteSubfamilyData.value?.spicId
  ) {
    actions.setErrors({
      primaryCigaretteSubfamily: 'Поле обязательно для заполнения',
    });

    return;
  }
  // добавление валидации поля secondaryCigaretteSubfamily если список не пустой
  if (
    secondaryCigaretteSubfamilyList.value?.length &&
    !secondaryCigaretteSubfamilyData.value?.spicId
  ) {
    actions.setErrors({
      secondaryCigaretteSubfamily: 'Поле обязательно для заполнения',
    });

    return;
  }

  props.componentData?.submitForm?.(values, actions);
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  brand: mixed().required(),
  primaryCigaretteSubfamily: mixed().notRequired(),
  cigaretteCountPerDayField: string()
    .required()
    .trim()
    .isValidCigaretteCountPerDay(),
  primaryBrandAgeField: mixed().required(),
  primaryBrandShareField: mixed().required(),
  brandAll: mixed().required(),
  secondaryCigaretteSubfamily: mixed().notRequired(),
});
// </editor-fold>
</script>
<template>
  <FormWrapper class="survey-form" heading="Опрос">
    <template #content>
      <VeeForm
        v-slot="{ isSubmitting }"
        :validation-schema="validationSchema"
        class="survey-form__form"
        @submit="sendForm"
      >
        <div class="survey-form__section survey-form__section--without-offset">
          <div class="survey-form__title">
            <h3 class="survey-form__font survey-form__font--title">
              Какую марку вы покупали и курили чаще всего в течение последних
              недель?
            </h3>
          </div>

          <UiSelect
            v-if="marks?.length"
            v-model="brandData"
            :options="marks"
            label="Марка"
            hint="Выберите из списка"
            show-errors
            name="brand"
            track-displayed-by="name"
            track-by="spicId"
            is-clear-button
            class="survey-form__field"
          />

          <UiSelect
            v-model="primaryCigaretteSubfamilyData"
            :options="primaryCigaretteSubfamilyList"
            label="Продукт"
            hint="Выберите из списка"
            show-errors
            name="primaryCigaretteSubfamily"
            track-displayed-by="name"
            track-by="spicId"
            is-clear-button
            class="survey-form__field"
            :is-disabled="!primaryCigaretteSubfamilyList?.length"
          />
        </div>

        <div class="survey-form__section">
          <div class="survey-form__title">
            <h3 class="survey-form__font survey-form__font--title">
              Сколько сигарет вы выкуриваете за день?
            </h3>
          </div>

          <UiMaskInput
            v-model="cigaretteCountPerDayFieldData"
            :mask="'###'"
            unmasked
            name="cigaretteCountPerDayField"
            label="Введите количество"
            hint="Количество сигарет"
            class="survey-form__field"
            input-mode="numeric"
          />
        </div>

        <div class="survey-form__section">
          <div class="survey-form__title">
            <h3 class="survey-form__font survey-form__font--title">
              Как давно Вы начали курить эту марку?
            </h3>
          </div>

          <UiSelect
            v-if="intervalList?.length"
            v-model="primaryBrandAgeFieldData"
            :options="intervalList"
            label="выберите интервал"
            hint="Выберите из списка"
            show-errors
            name="primaryBrandAgeField"
            track-displayed-by="name"
            track-by="value"
            is-clear-button
            class="survey-form__field"
          />
        </div>

        <div class="survey-form__section">
          <div class="survey-form__title">
            <h3 class="survey-form__font survey-form__font--title">
              Из последних 10-ти купленных пачек, сколько составляли пачки этой
              марки?
            </h3>
          </div>

          <UiSelect
            v-if="percentList?.length"
            v-model="primaryBrandShareFieldData"
            :options="percentList"
            label="выберите процентное соотношение"
            hint="Выберите из списка"
            show-errors
            name="primaryBrandShareField"
            track-displayed-by="name"
            track-by="value"
            is-clear-button
            class="survey-form__field"
          />
        </div>

        <div class="survey-form__section">
          <div class="survey-form__title">
            <h3 class="survey-form__font survey-form__font--title">
              Если вашей основной марки нет в продаже, какую марку вы купите?
            </h3>
          </div>

          <UiSelect
            v-if="marks?.length"
            v-model="brandAllData"
            :options="marks"
            label="марка"
            hint="Выберите из списка"
            show-errors
            name="brandAll"
            track-displayed-by="name"
            track-by="spicId"
            is-clear-button
            class="survey-form__field survey-form__field--select"
          />

          <UiSelect
            v-model="secondaryCigaretteSubfamilyData"
            :options="secondaryCigaretteSubfamilyList"
            label="ПРОДУКТ"
            hint="Выберите из списка"
            show-errors
            name="secondaryCigaretteSubfamily"
            track-displayed-by="name"
            track-by="spicId"
            is-clear-button
            class="survey-form__field survey-form__field--select"
            :is-disabled="!secondaryCigaretteSubfamilyList?.length"
          />
        </div>

        <div class="survey-form__footer">
          <UiButton
            type="submit"
            class="survey-form__button survey-form__button--submit"
            text="Отправить"
            :is-disabled="isSubmitting"
          />
        </div>
      </VeeForm>
    </template>
  </FormWrapper>
</template>
<style scoped lang="scss">
.survey-form {
  &__font {
    &--title {
      @include text-s;

      color: $color-black-100;
    }
  }

  &__section {
    margin-top: em(36);

    &--without-offset {
      margin-top: em(48);

      @include media-breakpoint-down(lg) {
        margin-top: em(24);
      }

      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }
  }

  &__title {
    margin-bottom: em(32);
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: em(24);
    margin-top: em(48);
  }

  &__button {
    &--submit {
      min-width: em(240);
    }
  }

  &__field {
    &--select:deep(.ui-select) {
      --max-height: #{em(120)};
    }
  }
}
</style>
